import React from 'react';
import './ZenroProductsPage.css';
import ArrowDown from '../assets/ArrowDown.png';
import zenroHomePagePhoto from '../assets/pimasensScreenshots/zenro_HomePage.png';
import zenroHistoryPagePhoto from '../assets/pimasensScreenshots/zenro_historyPage.png';
import zenroHistoryExpandedPhoto from '../assets/pimasensScreenshots/zenro_HistoryExpanded.png'
import zenroHistoryExpandedPhoto2 from '../assets/pimasensScreenshots/zenro_HistoryExpanded2.jpg'
import zenroProdVideo from '../assets/zenroVideos/FinalComp_1.mp4';
import { LinearGradient } from 'react-text-gradients';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const ZenroProductPage = () => {
    return (
        <div className="ZenroProductPage">
            <NavBar />
            <main className='ZenroProductPage-Container'>
                <div className='ZenroPP-titleDiv'>
                    <h1 className='ZenroPP-Title'>
                        <LinearGradient gradient={['to left', '#7EA6FF ,#F67F8D']} className="Products-header">
                            Zenro
                        </LinearGradient>
                    </h1>
                    <p className='ZenroPP-TitleText'>Measure, Analyze, Report. All in one place.</p>
                    <img src={ArrowDown} alt="ArrowDown" className="ZenroPP-down-arrow" />
                </div>
                <div className="ZenroPP-Main-Content">
                    <div className="ZenroPP-50-50-first">
                        <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">Track your cortisol levels and other health metrics effortlessly</h2>
                            <p>The Zenro App monitors your health by analyzing hormonal signals from your body, and calculates a score. This value is compared to to the user's unique baseline, allowing the app to detect subtle changes and provide insights into your overall well-being.</p>
                        </div>
                        <span className="ZenroPP-ImgBlock">
                            <img src={zenroHomePagePhoto} alt="ArrowDown" className="ZenroPP-ImgBlock-Img1" />
                            <img src={zenroHistoryPagePhoto} alt="ArrowDown" className="ZenroPP-ImgBlock-Img2" />
                        </span>
                    </div>
                    <div className="ZenroPP-50-50-first">
                        <span className="ZenroPP-ImgBlock">
                            <img src={zenroHistoryExpandedPhoto} alt="ArrowDown" className="ZenroPP-ImgBlock-Img1" />
                            <img src={zenroHistoryExpandedPhoto2} alt="ArrowDown" className="ZenroPP-ImgBlock-Img3" />
                        </span>
                        <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">Generate instant feedback on every test</h2>
                            <p>Zenro generates personalized recommendations using a machine learning model trained on extensive health data. Based on your current status - whether it's high, low, severe, or normal - the app provides tailored guidance to help you maintain or improve your well-being effectively.</p>
                        </div>
                    </div>
                    <div className="ZenroPP-50-50-first">
                        <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">various accessibility features to explore your health portfolio in full</h2>
                            <ul className="ZenroPP-UL">
                                <li className="ZenroPP-LI">User history with a list of all tests performed by the user</li>
                                <li className="ZenroPP-LI">Graph depicting your levels of select horomones over time</li>
                                <li className="ZenroPP-LI">Morning and evening averages</li>
                                <li className="ZenroPP-LI">Detailed test views with feedback and export options</li>
                            </ul>
                        </div>
                        <span className="ZenroPP-VideoBlock">
                            <video autoPlay loop muted>
                                <source src={zenroProdVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </span>
                    </div>
                </div>
            </main>
            <BottomNav/>
        </div>
    );
};

export default ZenroProductPage;