import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import HomeLogo from '../assets/HomeLogo.png'
import blueBlackLogo from '../assets/BlueBackLogo.png'
import ArrowDown  from '../assets/ArrowDown.png'
import pimasens1 from '../assets/pimasensScreenshots/pimasens1.png';
import pimasens2 from '../assets/pimasensScreenshots/pimasens2.png';
import pimasens3 from '../assets/pimasensScreenshots/pimasens3.png';
import pimasens4 from '../assets/pimasensScreenshots/pimasens4.png';
import pimasens5 from '../assets/pimasensScreenshots/pimasens5.png';
import pimasens6 from '../assets/pimasensScreenshots/pimasens6.png';
import pimasens7 from '../assets/pimasensScreenshots/pimasens7.png';
import pimaspec1 from '../assets/pimaspecScreenshots/pimaspec1.png';
import pimaspec2 from '../assets/pimaspecScreenshots/pimaspec2.png';
import pimaspec3 from '../assets/pimaspecScreenshots/pimaspec3.png';
import pimaspec4 from '../assets/pimaspecScreenshots/pimaspec4.png';
import pimaspec5 from '../assets/pimaspecScreenshots/pimaspec5.png';
import pimaspec6 from '../assets/pimaspecScreenshots/pimaspec6.png';
import zenroProdVideo from '../assets/zenroVideos/FinalComp_1.mp4';
import agriloProdVideo from '../assets/AgriloVideos/FinalEdit_1.mp4'

const handleZenroClick = () => {
  window.location.href = 'https://zenro.pimasens.com'; 
};

const handleAgriloClick = () => {
  window.location.href = 'https://agrilo.pimasens.com'; 
};

const Home = () => {
  return (
      <div className="home">
            <Helmet>
                <meta name="description" content="Explore our suite of mobile applications" />
                <meta name="keywords" content="Mental Health, Agriculture, Therapy, Performance, Mobile App" />
                <meta name="author" content="Pimasens" />
                <meta property="og:title" content="Home - Pimasens" />
                <meta property="og:description" content="Explore our suite of mobile applications" />
                <meta property="og:url" content="https://www.pimasens.com/home" />
                <meta property="og:image" content="https://www.pimasens.com/BlackBackLogo.png" />
                <meta name="robots" content="index, follow" />
            </Helmet>
          <NavBar/>
          <div className="home-container">
            <div className='menu-background-logo-container'>
              <img src={HomeLogo} alt="Pimasens Logo"/>
            </div>
            <header className="Header-text">
            <h1 className='main-header1'>Powered By Precision</h1>
              <h1 className='main-header2'>Driven By Results</h1>
              <p className="main-header-info">
                  Our advanced mental health platform delivers real-time monitoring, empowering you to make confident, informed decisions for better outcomes.
              </p>
              <div className='menu-product-nav-container'>
              <button className='menu-product-nav-zenro-button' onClick={handleZenroClick}>Explore Zenro</button>
              </div>
            </header>
            <video autoPlay loop muted className="header-video">
                <source src={zenroProdVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
          </div>
          <div className="home-container">
            <header className="Header-text">
              <h1 className='main-header1'>Cultivating Innovation</h1>
              <h1 className='main-header2'>Harvesting Success</h1>
              <p className="main-header-info">
              Our cutting-edge agriculture platform provides real-time analytics, enabling you to make smarter, data-driven decisions for thriving farms and sustainable growth.
              </p>
              <div className='menu-product-nav-container'>
                <button className='menu-product-nav-agrilo-button' onClick={handleAgriloClick}>Explore Agrilo</button>
              </div>
            </header>
            <video autoPlay loop muted className="header-video">
                <source src={agriloProdVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
          </div>
          <BottomNav/>
    </div>
  );
};

export default Home;
