import React, { useState, useEffect } from 'react';
import './RecentAverageBox.css';
import axios from 'axios';
import AverageTestBox from '../components/AverageTestBox';
const RecentAverageBox = ({ patient, access }) => {

  // const headers = {
  //     'Authorization': 'Bearer ' + access
  // }

  // const onSearchClick = () => {
  //     if (range.from !== '') {
  //         setFrom(format(range.from, 'yyyy-MM-dd'))
  //     }
  //     if (range.to !== '') {
  //         setTo(format(range.to, 'yyyy-MM-dd'))
  //     }

  //     const queryParams = {
  //         StartDate: from,
  //         EndDate: to,
  //         TestSeverity: selectedSeverity,
  //     };



  //     axios.get('https://www.pimasens.com/api/getTestFilter/', {
  //         headers: headers,
  //         params: queryParams
  //     })
  //         .then(response => {
  //             const data = response.data;

  //             if (data.error == "No tests found") {
  //                 setError('An error occurred');
  //             } else {
  //                 const tests = data;
  //                 setTests(tests)
  //             }
  //         })
  //         .catch(error => {
  //             setError('An error occurred');
  //         });
  // }

  // get current month
  const currentDate = new Date();
  const currentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth());
  const lastMonth = new Date(currentMonth); 
  lastMonth.setMonth(currentMonth.getMonth() - 1);
  const Month3 = new Date(currentMonth); 
  Month3.setMonth(currentMonth.getMonth() - 2);
  const Month4 = new Date(currentMonth); 
  Month4.setMonth(currentMonth.getMonth() - 3);
  const [average1, setAverage1] = useState([]);
  const [average2, setAverage2] = useState([]);
  const [average3, setAverage3] = useState([]);
  const [average4, setAverage4] = useState([]);

  useEffect(() => {
    var postData = {
      Year: currentMonth.getFullYear(),
      Month: currentMonth.getMonth()+1,
      SensorID: 1,
      DoctorAssignmentID: patient.DoctorAssignmentID
    };
    axios.post('https://www.pimasens.com/api/getPatientAverageTest/', postData, {
      headers: {
        'Authorization': 'Bearer ' + access.access,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setAverage1({
          ...response.data,
          month: currentMonth.toLocaleString('default', { month: 'long' }).toUpperCase()
        });
      })
      .catch(error => {
        setAverage1({
          "average_test_result": 0,
          "test_count": 0,
          "highest_test_result": 0,
          "lowest_test_result": 0,
          "month": currentMonth.toLocaleString('default', { month: 'long' }).toUpperCase()
        });
      });
      //get average month 2

      var postData2 = {
        Year: lastMonth.getFullYear(),
        Month: lastMonth.getMonth()+1,
        SensorID: 1,
        DoctorAssignmentID: patient.DoctorAssignmentID
      };
      axios.post('https://www.pimasens.com/api/getPatientAverageTest/', postData2, {
        headers: {
          'Authorization': 'Bearer ' + access.access,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          setAverage2({
            ...response.data,
            month: lastMonth.toLocaleString('default', { month: 'long' }).toUpperCase()
          });
        })
        .catch(error => {
          setAverage2({
            "average_test_result": 0,
            "test_count": 0,
            "highest_test_result": 0,
            "lowest_test_result": 0,
            "month": lastMonth.toLocaleString('default', { month: 'long' }).toUpperCase()
          });
        });

    //month 3
    var postData3 = {
      Year: Month3.getFullYear(),
      Month: Month3.getMonth()+1,
      SensorID: 1,
      DoctorAssignmentID: patient.DoctorAssignmentID
    };
    axios.post('https://www.pimasens.com/api/getPatientAverageTest/', postData3, {
      headers: {
        'Authorization': 'Bearer ' + access.access,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setAverage3({
          ...response.data,
          month: Month3.toLocaleString('default', { month: 'long' }).toUpperCase()
        });
      })
      .catch(error => {
        setAverage3({
          "average_test_result": 0,
          "test_count": 0,
          "highest_test_result": 0,
          "lowest_test_result": 0,
          "month": Month3.toLocaleString('default', { month: 'long' }).toUpperCase()
        });
      });

    //month 4
    var postData4 = {
      Year: Month4.getFullYear(),
      Month: Month4.getMonth()+1,
      SensorID: 1,
      DoctorAssignmentID: patient.DoctorAssignmentID
    };
    axios.post('https://www.pimasens.com/api/getPatientAverageTest/', postData4, {
      headers: {
        'Authorization': 'Bearer ' + access.access,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        setAverage4({
          ...response.data,
          month: Month4.toLocaleString('default', { month: 'long' }).toUpperCase()
        });
      })
      .catch(error => {
        setAverage4({
          "average_test_result": 0,
          "test_count": 0,
          "highest_test_result": 0,
          "lowest_test_result": 0,
          "month": Month4.toLocaleString('default', { month: 'long' }).toUpperCase()
        });
      });
  }, [])



  return (
    <div className="RecentAverageBox">
      <h1 className="RecentAverageBox-header1">RECENT  RESULTS</h1>
      <h1 className='RecentAverageBox-header2'>CORTISOL</h1>
      <div className='RecentAverageBox-boxes'>
        <div className='RecentAverageBox-box1'>
          <AverageTestBox testData={average1} />
        </div>
        <div className='RecentAverageBox-box2'>
          <AverageTestBox testData={average2} />
        </div>
        <div className='RecentAverageBox-box3'>
          <AverageTestBox testData={average3} />
        </div>
      </div>
    </div>
  );
};


export default RecentAverageBox;