
import './AgriloOverviewBox.css';
import AgriloPlaceholder from '../assets/agriloIcons/AgriloPlaceholder.png';
import OverviewIcon from '../assets/agriloIcons/OverviewIcon.png';
import AgriloOverviewDisplay from './AgriloOverviewDisplay';
import React, { useState, useEffect } from 'react';

const AgriloOverviewBox = () => {
    const [username, setUsername] = useState(null);
    const [averages1, setAverages1] = useState([
        {
            range: "Q4",
            months: "OCT - DEC",
        },
        // {
        //     name: "Nitrate",
        //     number: 35,
        //     rangeLow: 10,
        //     rangeHigh: 30,
        //     rangeMax: 40
        // },
        // {
        //     name: "Phosphorus",
        //     number: 280,
        //     rangeLow: 150,
        //     rangeHigh: 250,
        //     rangeMax: 350
        // },
        // {
        //     name: "Potassium",
        //     number: 15,
        //     rangeLow: 10,
        //     rangeHigh: 30,
        //     rangeMax: 40
        // },
        // {
        //     name: "calcium",
        //     number: 5,
        //     rangeLow: 5,
        //     rangeHigh: 10,
        //     rangeMax: 15
        // },
    ]);
    // const averages1 = [
    //     {
    //         range: "Q4",
    //         months: "OCT - DEC",
    //     },
    //     {
    //         name: "Nitrate",
    //         number: 35,
    //         rangeLow: 10,
    //         rangeHigh: 30,
    //         rangeMax: 40
    //     },
    //     {
    //         name: "Phosphorus",
    //         number: 280,
    //         rangeLow: 150,
    //         rangeHigh: 250,
    //         rangeMax: 350
    //     },
    //     {
    //         name: "Potassium",
    //         number: 15,
    //         rangeLow: 10,
    //         rangeHigh: 30,
    //         rangeMax: 40
    //     },
    //     {
    //         name: "calcium",
    //         number: 5,
    //         rangeLow: 5,
    //         rangeHigh: 10,
    //         rangeMax: 15
    //     },
    // ];
    const averages2 = [
        {
            range: "Q3",
            months: "JUL - SEP",
        },
        // {
        //     name: "Nitrate",
        //     number: 45,
        //     rangeLow: 10,
        //     rangeHigh: 30,
        //     rangeMax: 40
        // },
        // {
        //     name: "Phosphorus",
        //     number: 280,
        //     rangeLow: 150,
        //     rangeHigh: 250,
        //     rangeMax: 350
        // },
    ];

    useEffect(() => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('Username=')) {
                // Extract the cookie value
                var username = cookie.substring('Username='.length);
                setUsername(username)
            }
        }
        if (username == "farmDemo") {
            setAverages1(
                [
                {
                    range: "Q4",
                    months: "OCT - DEC",
                },
                {
                    name: "Nitrate",
                    number: 35,
                    rangeLow: 20,
                    rangeHigh: 40,
                    rangeMax: 60
                },
            ]
            )
}
    }, []);

return (
    <div className="AgriloOverviewBox">
        <img src={OverviewIcon} alt="shot1" className="AgriloOverviewBox-icon" />
        <h1 className='AgriloOverviewBox-h1'> Overview</h1>
        <div className='AgriloOverviewBox-line1' />
        <div className='AgriloOverviewBox-placeholder'>
            {averages1 && (
                <AgriloOverviewDisplay averages={averages1} />
            )}
            {averages2 && (
                <AgriloOverviewDisplay averages={averages2} />
            )}
        </div>
    </div>
);
};

export default AgriloOverviewBox;
