import React, { useState, useEffect } from 'react';
import './AgriloCalendar.css';
import AgriloPlaceholder from '../assets/agriloIcons/AgriloPlaceholder.png';
import CalendarIcon from '../assets/agriloIcons/CalendarIcon.png';
import Calendar from 'react-calendar';
import { da } from 'react-day-picker/locale';

const AgriloCalendar = ({ task, onTaskFilter }) => {
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    // Function to filter tasks based on the current date
    const filterTasks = (currentDate) => {
      const currentMonth = currentDate.getMonth();
      const startMonth = new Date(currentDate.getFullYear(), currentMonth, 1);
      const endMonth = new Date(currentDate.getFullYear(), currentMonth + 2, 0);

      const filtered = task.filter(t => {
        const startDate = new Date(t.TimeCreated);
        const endDate = new Date(t.TimeCreated);
        return startDate <= endMonth && endDate >= startMonth;
      });
    };

    // Call filterTasks initially with the current date
    filterTasks(value);
  }, [value, task]); // Dependency array: re-run when 'value' or 'task' changes


  // const handleMonthChange = (newValue) => {
  //   onChange(newValue);
  //   console.log(newValue);
  //   const currentMonth = newValue.getMonth(); // Get the month (0-indexed)

  //   // Calculate the date range for the two months
  //   const startMonth = new Date(newValue.getFullYear(), currentMonth, 1);
  //   const endMonth = new Date(newValue.getFullYear(), currentMonth + 2, 0); // 0 gets the last day of the previous month

  //   // Filter the tasks based on the date range
  //   const filtered = task.filter(t => {
  //     const startDate = new Date(t.StartDate);
  //     const endDate = new Date(t.EndDate);
  //     return startDate <= endMonth && endDate >= startMonth;
  //   });

  const handleDayClick = (newValue) => {
    onChange(newValue);
    console.log(newValue);

    // Create a Date object for the start of the day from newValue
    const startOfDay = new Date(newValue.getFullYear(), newValue.getMonth(), newValue.getDate());

    // Create a Date object for the end of the day from newValue
    const endOfDay = new Date(newValue.getFullYear(), newValue.getMonth(), newValue.getDate(), 23, 59, 59, 999);

    // Filter the tasks based on the date range
    const filtered = task.filter(t => {
      const taskDate = new Date(t.TimeCreated);
      return taskDate >= startOfDay && taskDate <= endOfDay;
    });
    console.log(filtered)
    // Call the callback function to update the parent's state
    onTaskFilter(filtered);
  };

  const handleNavigationClick = (newValue) => {
    onChange(newValue); // Update the value state when navigating with arrows
  };

  const highlightRange = ({ date, view }) => {
    if (view === 'month') {
      for (let i = 0; i < task.length; i++) {
        
        if (new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999) >= new Date(task[i].TimeCreated) && date <= new Date(task[i].TimeCreated)) {
          return 'highlight-' + "orange";
        }
      }
    }
    // No highlighting for other views (year, decade)
    return null;
  };

  return (
    <div className="AgriloCalendar">
      <img src={CalendarIcon} alt="shot1" className="AgriloCalendar-icon" />
      <h1 className='AgriloCalendar-h1'> Calendar</h1>
      <div className='AgriloCalendar-line1' />
      <Calendar
        onChange={handleDayClick}
        value={value}
        tileClassName={highlightRange}
        onActiveStartDateChange={({ activeStartDate }) => handleNavigationClick(activeStartDate)}
        showDoubleView={true}
        showFixedNumberOfWeeks={false}
      />
    </div>
  );
};

export default AgriloCalendar;