import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Agrilo.css';
import AgriloNavagatorBar from '../components/AgriloNavagatorBar';
import agriloIconTop from '../assets/agriloIcons/AgriloTop.png';
import AgriloHistoryPage from './AgriloHistoryPage';
import AgriloSummaryPage from './AgriloSummaryPage';
import axios from 'axios';

const Agrilo = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('summary');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [access, setAccess] = useState(null);
    const [error, setError] = useState('');
    const [prefill, setPrefill] = useState(null);
    const handleIconClicked = () => {
        navigate('/');
    }

    useEffect(() => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('access=')) {
                // Extract the cookie value
                var accessToken = cookie.substring('access='.length);
                setAccess(accessToken);
            }
        }
        if (!accessToken) {
            navigate('/login');
        } else {
            axios.get('https://www.pimasens.com/api/getUser/', {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                }
            })
                .then(response => {
                    const data = response.data;
                    if (data.error === "No tests found") {
                        setError('An error occurred');
                        navigate('/login');
                    } else {
                        document.cookie = "Username=" + data.Username + ";path=/";
                        if (data.UserType == "AgriloProf") {
                            navigate('/agriloProf');
                        }
                    }
                    setLoading(false); // Set loading to false after data is fetched
                })
                .catch(error => {
                    setError('An error occurred');
                    setLoading(false); // Set loading to false even if there's an error
                });
                axios.get('https://pimasens.com/api/agriloTest/prefill/', {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken
                    }
                })
                    .then(response => {
                        const data = response.data;
                        if (data.error === "No tests found") {
                            setError('An error occurred');
                            navigate('/login');
                        } else {
                            console.log(data)

                            setPrefill(data)
                        }
                        setLoading2(false); // Set loading to false after data is fetched
                    })
                    .catch(error => {
                        setError('An error occurred');
                        setLoading2(false); // Set loading to false even if there's an error
                    });
        }
    }, [access, navigate]);

    return (
        <div className="Agrilo">
            {loading&&loading2 ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>

                    <div className='Agrilo-icon' onClick={() => handleIconClicked()}>
                        <img src={agriloIconTop} alt="shot1" className="agriloIconTop" />
                        <h1 className='Agrilo-h1'>Agrilo</h1>
                    </div>
                    <AgriloNavagatorBar onMenuItemSelect={setSelectedMenuItem} />
                    {selectedMenuItem === 'history' && <AgriloHistoryPage access={access} prefill={prefill}/>}
                    {selectedMenuItem === 'summary' && <AgriloSummaryPage access={access} prefill={prefill}/>}
                </>
            )}
        </div>

    );

};



export default Agrilo;