import React from 'react';
import './PrivacyPolicyPage.css';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const PrivacyPolicyPage = () => {
    return (
      <div className="PrivacyPolicyPage">
        <NavBar />
        <div className='PrivacyPolicyPage-Container'>
          <div className='PrivacyPolicyPage-box'>
              <h1 className='PrivacyPolicyPage-header'>PimaSens Privacy Policy</h1>
              <h2>Effective: November 11, 2024</h2>
              <h3>This privacy policy explains how we collect, use, and share information about you when you use our webapp.</h3>
              <h2>Information We Collect</h2>
              <h3>We collect information upon account creation. This includes your name, email address, phone number, healthcare number, and other details necessary for the app to function effectively.</h3>
              <h2>How We Use Your Information</h2>
              <h3>We use the information we collect to streamline the process of sharing test results with medical professionals. The information requested is required to adaquately perform that task</h3>
              <h2>Sharing Your Information</h2>
              <h3>We do not share your personal information with third parties except as required by law. Only users may share their information.</h3>
              <h2>Cookies</h2>
              <h3>Please see our <a className='PrivacyPolicyPage-links' href="/about/cookie-policy">Cookie Policy</a> for more information about how we use cookies</h3>
              <h2>Changes to This Policy</h2>
              <h3>We may update this privacy policy from time to time. We will notify you of any significant changes by posting the new policy on this page and updating the date above</h3>
          </div>
        </div>
        <BottomNav />
    </div>
  );
};

export default PrivacyPolicyPage;