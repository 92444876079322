import React from 'react';
import { Helmet } from "react-helmet";
import './ContactPage.css';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const ContactPage = () => {
    return (
        <div className="Contact">
            <Helmet>
                <meta name="description" content="Get in contact with one of our specialists, we're happy to help!" />
                <meta name="keywords" content="Mental Health, Agriculture, Therapy, Performance, Mobile App" />
                <meta name="author" content="Pimasens" />
                <meta property="og:title" content="Contact - Pimasens" />
                <meta property="og:description" content="Get in contact with one of our specialists, we're happy to help!" />
                <meta property="og:url" content="https://www.pimasens.com/contact-us" />
                <meta property="og:image" content="https://www.pimasens.com/BlackBackLogo.png" />
                <meta name="robots" content="index, follow" />
            </Helmet>
        <NavBar />
        <h1 className='ContactHeader'>Contact</h1>
        <div className='Contact-box '>
              <h2>Support</h2>
              <p>Our team is here for any questions or support. Contact us at: <a className='ContactBox-email' href="mailto:pimasensinc@gmail.com">pimasensinc@gmail.com</a></p>
        </div>
        <BottomNav />
    </div>
  );
};

export default ContactPage;