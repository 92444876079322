import React, { useState, useEffect } from 'react';
import './AgriloTestsBox.css';
import TestsIcon from '../assets/agriloIcons/TestsIcon.png';
import AgriloRecentTestBox from './AgriloRecentTestBox';
const AgriloTestsBox = ({ testData, selectedTest, onTestSelect, access, prefill }) => {
    const testColor = ['#CFBF05', '#4C6CFD', '#C83A31']

    const [givenTest, setTests] = useState(testData)

    const handleItemClick = (test) => {
        if (selectedTest !== test) { // Only update if a different test is clicked
            onTestSelect(test);
        }
    };

    const ratingAssign = (ppm) => {
        if (ppm > 40) {
            return 2
        } else if (ppm < 20) {
            return 0
        } else {
            return 1
        }
    }

    console.log(prefill)

    return (
        <div className="AgriloTestsBox">
            <div className='AgriloTestBox-header'>
                <img src={TestsIcon} alt="shot1" className="AgriloTestsBox-icon" />
                <h1 className='AgriloTestsBox-h1'> Tests</h1>
            </div>
            <div className='AgriloTestsBox-line1' />
            <h2 className='AgriloTestsBox-h2 recent'>Recent</h2>
            <div className='AgriloTestsBox-recent-test'>
                {testData[0] && (
                    <AgriloRecentTestBox test={testData[0]} prefill={prefill} />
                )}
                {testData[1] && (
                    <AgriloRecentTestBox test={testData[1]} prefill={prefill} />
                )}
            </div>
            <h2 className='AgriloTestsBox-h2 all'>All</h2>
            <div className='AgriloTestsBox-line2' />

            {/* list of test container */}
            <div className="AgriloTestsBox-item-container">
                <nav className="AgriloTestsBox-items">

                    <ul className='AgriloTestsBox-ul'>
                        {givenTest.slice().map(test => (
                            <li
                                key={test.TestID}
                                className={`AgriloTestsBox-test-item ${selectedTest === test ? 'active' : ''}`}
                                onClick={() => handleItemClick(test)}
                            >
                                <span className="AgriloTestsBox-item-name">{prefill["specs"]["0"]["SpecName"]}</span>
                                <span className="AgriloTestsBox-item-date">{new Date(test.TimeCreated).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span>
                                <span className="AgriloTestsBox-plant-type">
                                    {prefill["crop_types"][test.CropTypeID - 1]["CropName"]}
                                </span>
                                <span className="AgriloTestsBox-chemical-type">
                                    {prefill["chemicals"][test.ChemicalID - 1]["ChemicalName"]}
                                </span>
                                <div className='AgriloTestsBox-unit-amount-box'>
                                    <span className="AgriloTestsBox-unit-amount" style={{ color: testColor[ratingAssign(test.PpmRecorded)] }} >{test.PpmRecorded}</span>
                                    <span className="AgriloTestsBox-units">PPM</span>
                                </div>


                                {/* <span className="AgriloTestsBox-item-name">{test.name}</span> */}

                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

        </div>
    );
};

export default AgriloTestsBox;

