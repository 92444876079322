// src/components/Container.js
import React from 'react';
import './BottomNav.css';
import SmallLogo from '../assets/PimasensLogoSmall.png'

const bottomNavItems = [
    [{ label: "Products", link: "/products" }],
    [{ label: "Pricing", link: "/pricing" }],
    [{ label: "About", link: "/about-us" }, { label: "Cookie Policy", link: "/about/cookie-policy" }, { label: "Privacy Policy", link: "/about/privacy-policy" }],
    [{ label: "Contact", link: "/contact-us" }],
  ];

const BottomNav = () => {
  return (
    <nav className="bottom-nav-container">
        <div className='BottomNav'>
            <img src={SmallLogo} alt="" className='small-logo'/>
            <div className='BottomNavOptions'>
                {bottomNavItems.map((category, categoryIndex) => (
                    <ul key={categoryIndex} className="BottomNavCategory">
                    {category.map((item, itemIndex) => (
                        <li key={itemIndex} className="BottomNav-main-options-li">
                            <a href={item.link}>{item.label}</a>
                        </li>
                    ))}
                    </ul>
                ))}
            </div>
        </div>
    </nav>
  );
};

export default BottomNav;
