import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SelectHistoryBox.css';
const SelectHistoryBox = ({ testData, access }) => {
    const SeverityColor = ['#f54244', '#fce93d', '#47a857', '#fce93d', '#f54244']
    const SeverityRecommendation = ['Low Cortisol', 'Low Cortisol', 'Normal Cortisol', 'Elevated Cortisol', 'Elevated Cortisol']
    const [resetNotes, setResetNotes] = useState(true);
    const [notes, setNotes] = useState('');
    const [error, setError] = useState('');
    const [explanation, setExplanation] = useState('')
    const [formattedText, setFormattedText] = useState('')
    useEffect(() => {
        if (testData == null) {
            setNotes("")
            setResetNotes(true)
        } else {
            setExplanation(testData.Feedback.split("\n\n")[0])
            setNotes(testData.Notes);
            setFormattedText(testData.Feedback.split('\n').slice(1).map((line, index) => {
                const boldStart = line.indexOf('**');
                const boldEnd = line.indexOf('**', boldStart + 2);
                if (boldStart !== -1 && boldEnd !== -1) {
                    const boldText = line.substring(boldStart + 2, boldEnd);
                    const normalText = line.substring(boldEnd + 2);
                    return (
                        <li key={index} style={{ marginBottom: '1em' }}>
                            <strong>{boldText}</strong>{normalText}
                        </li>
                    );
                } else {
                    return <p key={index} style={{ marginBottom: '1em' }}>{line}</p>;
                }
            }))
        }
    }, [testData]);



    const handleSaveClick = () => {
        let dataToSend = { Notes: notes, TestID: testData.TestID };

        axios.put('https://www.pimasens.com/api/updateTestNotes/', dataToSend, {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }

    if (!testData || Object.keys(testData).length === 0) { // Check if testData is empty
        return (
            <div className="SelectHistoryBox">
                <div className="SelectHistoryBox-info-box">
                    <p className="no-test-message">No test</p>
                </div>
            </div>
        );
    }
    return (
        <div className="SelectHistoryBox">
            <h1 className='SelectHistoryBox-header-text'>HIGHLIGHT</h1>
            <div className='SelectHistoryBox-info-box'>
                <div className='v1' />
                <h2 className='SelectHistoryBox-h2 test'>Test</h2>
                <h3 className='SelectHistoryBox-h3'>Cortisol Test</h3>
                <h2 className='SelectHistoryBox-h2'>Date</h2>
                <h3 className='SelectHistoryBox-h3'>{new Date(testData.Date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}</h3>
                <h2 className='SelectHistoryBox-h2'>Time</h2>
                <h3 className='SelectHistoryBox-h3'>{new Date(`1970-01-01T${testData.Time}Z`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h3>

                <div className='SelectHistoryBox-rectangle'>
                    <h4 className='SelectHistoryBox-h4'>Cortisol</h4>
                </div>
            </div>

            <div className='SelectHistoryBox-status-icon' style={{ borderColor: SeverityColor[testData.TestSeverity - 1] }} />
            <div className='SelectHistoryBox-result-box'>
                <h1 className='SelectHistoryBox-result' style={{ color: SeverityColor[testData.TestSeverity - 1] }}>{parseFloat(testData.TestResult).toFixed(2)}</h1>
                <h1 className='SelectHistoryBox-result-format' style={{ color: SeverityColor[testData.TestSeverity - 1] }}>ng/mL</h1>
            </div>

            <div className='SelectHistoryBox-recommendation-box'>
                <div className='SelectHistoryBox-v2' />
                <h2 className='SelectHistoryBox-h5'>Recommendation</h2>
                <div className='SelectHistoryBox-status-box' style={{ backgroundColor: SeverityColor[testData.TestSeverity - 1] }}>
                    <h4 className='SelectHistoryBox-h6' >{SeverityRecommendation[testData.TestSeverity - 1]}</h4>
                </div>
            </div>
            {/* <h2 className='SelectHistoryBox-h7'>Notes:</h2>
            {resetNotes && (
                <textarea placeholder="Enter note here" className='SelectHistoryBox-notes-text-box' onChange={(e) => setNotes(e.target.value)} value={notes}></textarea>
            )}
            <button className='SelectHistoryBox-save-note-button' onClick={() => handleSaveClick()}>Save</button> */}
            <div className='SelectHistoryBox-recommendation-text-box'>
                <h2 className='SelectHistoryBox-h5'>Summary</h2>
                <h2 className='SelectHistoryBox-diagnosis'>{explanation}</h2>
            </div>
            <div className='SelectHistoryBox-recommendation-point-box'>
                <ol className='SelectHistoryBox-recommendations'>{formattedText}</ol>
            </div>
        </div>
    );
};

export default SelectHistoryBox;