import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AgriloPinnedBox.css';
import PinnedIcon from '../assets/agriloIcons/PinnedIcon.png';

const AgriloPinnedBox = () => {
    const [weather, setWeather] = useState([])
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const [error, setError] = useState('');

    useEffect(() => {
        axios.get('https://api.openweathermap.org/data/2.5/weather?lat=53.4470177&lon=-113.5847808&appid=7bdd5b564821d35f759de2e058fdbabe', {
            // headers: {
            //     'Authorization': 'Bearer ' + access
            // }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    setWeather(data);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }, []);


    return (
        <div className="AgriloPinnedBox">
            <img src={PinnedIcon} alt="shot1" className="AgriloPinnedBox-icon" />
            <h1 className='AgriloPinnedBox-h1'>Pinned</h1>
            <div className='AgriloPinnedBox-line1' />
            <div className='AgriloPinnedBox-items'>
                {/* <ChemOverviewBox
                    name="Nitrate"
                    value1="24"
                    metric="PPM"
                    value2="18"
                    month1="JUN"
                    value3="22"
                    month2="MAY"
                    value4="20"
                    month3="APR"
                /> */}
                {/* <OverviewBox
                    type="soil"
                    value="7.0"
                    metric="PPM"
                    date="PRE-SEASON"
                /> */}
                <OverviewBox
                    type="Moisture"
                    value="45"
                    metric="%"
                    date="JULY AVG"
                />
                <OverviewBox
                    type="Temperature"
                    value="30°"
                    metric="CELCIUS"
                    date="JULY AVG"
                />
                <OverviewBox
                    type="Rainfall"
                    value="0.5"
                    metric="INCHES"
                    date="24h"
                />
            </div>
        </div>
    );
};

export default AgriloPinnedBox;

const OverviewBox = (prop) => {
    return (
        <div className="AgriloPinnedBox-overview">
            <h1 className="AgriloPinnedBox-overview-header">Overview</h1>
            {/* <svg className='AgriloPinnedBox-overview-dots' xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                <circle cx="2" cy="2" r="2" fill="#B4B4B4" />
                <circle cx="8" cy="2" r="2" fill="#B4B4B4" />
                <circle cx="14" cy="2" r="2" fill="#B4B4B4" />
            </svg> */}
            <div className='AgriloPinnedBox-overview-line' />
            <div className='AgriloPinnedBox-overview-type'>{prop.type}</div>
            <h1 className='AgriloPinnedBox-overview-value'>{prop.value}</h1>
            <h1 className='AgriloPinnedBox-overview-metric'>{prop.metric}</h1>
            <h1 className='AgriloPinnedBox-overview-date'>{prop.date}</h1>
        </div>
    );
};

const ChemOverviewBox = (prop) => {
    return (
        <div className="AgriloPinnedBox-ChemOverviewBox">
            <h1 className="AgriloPinnedBox-ChemOverviewBox-header">Chemical Overview</h1>
            <div className='AgriloPinnedBox-ChemOverviewBox-line' />
            <svg className='AgriloPinnedBox-ChemOverviewBox-dots' xmlns="http://www.w3.org/2000/svg" width="16" height="4" viewBox="0 0 16 4" fill="none">
                <circle cx="2" cy="2" r="2" fill="#B4B4B4" />
                <circle cx="8" cy="2" r="2" fill="#B4B4B4" />
                <circle cx="14" cy="2" r="2" fill="#B4B4B4" />
            </svg>
            <div></div>
            <div className="AgriloPinnedBox-ChemOverviewBox-box one">
                <div className='AgriloPinnedBox-ChemOverviewBox-type'>{prop.name}</div>
                {/* last test */}
                <h1 className='AgriloPinnedBox-ChemOverviewBox-big-value'>{prop.value1}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-metric1'>{prop.metric}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-label'>LAST TEST</h1>
            </div>
            {/* month1 */}
            <div className='AgriloPinnedBox-ChemOverviewBox-box two'>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-value two'>{prop.value2}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-metric two'>{prop.metric}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-month one'>{prop.month1}</h1>
            </div>
            {/* month2 */}
            <div className='AgriloPinnedBox-ChemOverviewBox-box three'>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-value three'>{prop.value3}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-metric three'>{prop.metric}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-month two'>{prop.month2}</h1>
            </div>
            {/* month3 */}
            <div className='AgriloPinnedBox-ChemOverviewBox-box four'>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-value four'>{prop.value4}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-metric four'>{prop.metric}</h1>
                <h1 className='AgriloPinnedBox-ChemOverviewBox-month three'>{prop.month3}</h1>
            </div>
        </div>
    );
};