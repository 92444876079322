// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { RiCloseLargeLine, RiMenuLine } from '@remixicon/react';
import './NavBar.css';
import SmallLogo from '../assets/PimasensLogoSmall.png'
import HomeLogo from '../assets/HomeLogo.png'
import SignUpPage from "../pages/SignUpPage";

const NavBar = () => {
  var subdomain = window.location.hostname.split('.');
  if (subdomain[0] === 'www') {
    subdomain = subdomain[1]
  } else {
    subdomain = subdomain[0]
  }
  const [username, setUsername] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isPimasens, setPimaChecker] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignUpClick = () => {
    navigate('/sign-up');
  };

  const handleUserClick = () => {
    if (subdomain === 'agrilo') {
      navigate('/agrilo');
    } else {
      navigate('/zenro');
    }

  };
  var navItems = [];
  switch (subdomain) {
    case 'agrilo':
      navItems = [
        { label: "Sensor Guidelines", link: "/products" },
        { label: "Pricing", link: "/pricing" },
        { label: "About", link: "/about-us" },
        { label: "Contact", link: "/contact-us" },
      ];
      break;
    case 'zenro':
      navItems = [
        { label: "Pricing", link: "/pricing" },
        { label: "About", link: "/about-us" },
        { label: "Contact", link: "/contact-us" },
      ];
      break;
    default:
      navItems = [
        { label: "Products", link: "/products" },
        { label: "Pricing", link: "/pricing" },
        { label: "About", link: "/about-us" },
        { label: "Contact", link: "/contact-us" },
      ];

      break;
  }

  useEffect(() => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('access=')) {
        // Extract the cookie value
        var accessToken = cookie.substring('access='.length);
      }
      if (cookie.startsWith('Username=')) {
        // Extract the cookie value
        var username = cookie.substring('Username='.length);
        setUsername(username)
      }
    }
    if (subdomain == "pimasens") {
      setPimaChecker(true);
    }
    if (accessToken) {
      setLoggedIn(true)
      console.log(username)
    }
  }, []);

  return (
    <header className="TopNavbar-Container">
      <nav className="TopNavbar">
        <Link to="/home" className="nav-menu-logo">
          <img src={SmallLogo} alt="Pimasens Logo" />
        </Link>

        <ul className="nav-options">
          {navItems.map((item, index) => (
            <li key={index} className="nav-option-li">
              <a href={item.link}>{item.label}</a>
            </li>
          ))}
        </ul>

        {(!loggedIn && !isPimasens) && (
          <div className="nav-buttons">
            <button className="nav-button login" onClick={handleLoginClick}>Log In</button>
            <button className="nav-button signup" onClick={handleSignUpClick}>Sign Up</button>
          </div>
        )}
        {(loggedIn && !isPimasens) && (
          <div className="nav-user">
            <button className="nav-button user" onClick={handleUserClick}>{username}</button>
          </div>
        )}
        <button className="mobile-nav-open-button" onClick={() => setIsMenuOpen(true)}>
          <RiMenuLine />
        </button>
      </nav>

      <nav className={`mobile-nav-menu ${isMenuOpen ? 'show-menu' : ''}`}>
        <img src={HomeLogo} alt="Pimasens Logo" className='mobile-nav-menu-logo' />
        <ul className="mobile-nav-options">
          {navItems.map((item, index) => (
            <li key={index} className="mobile-nav-option-li">
              <a href={item.link}>{item.label}</a>
            </li>
          ))}
          <hr style={{ width: "60vw", display: "inline", position: "static", alignItems: "start", margin: "0" }} />
          <li className="mobile-nav-option-li" style={{ display: loggedIn ? 'none' : 'flex' }}>
            <a href="/login">Log In</a>
          </li>
          <li className="mobile-nav-option-li" style={{ display: loggedIn ? 'none' : 'flex' }}>
            <a href="/sign-up">Create Account</a>
          </li>
          <li className="mobile-nav-option-li" style={{ display: !loggedIn ? 'none' : 'flex' }}>
            <a href="/zenro">Account</a>
          </li>
        </ul>
        <button className="mobile-nav-close-button" onClick={() => setIsMenuOpen(false)}>
          <RiCloseLargeLine className='sensor-download-image' />
        </button>

      </nav>
    </header>
  );
};

export default NavBar;
