import React from 'react';
import './ZenroProductsPage.css';
import ArrowDown from '../assets/ArrowDown.png';
import agriloLoginPhoto1 from '../assets/pimaspecScreenshots/agrilo_Login1.png';
import agriloLoginPhoto2 from '../assets/pimaspecScreenshots/agrilo_Login2.png';
import agriloSummaryPhoto1 from '../assets/pimaspecScreenshots/agrilo_summary.png';
import agriloSummaryPhoto2 from '../assets/pimaspecScreenshots/agrilo_Test.png';
import agriloResultsPhoto1 from '../assets/pimaspecScreenshots/agrilo_TestHistory.png';
import agriloResultsPhoto2 from '../assets/pimaspecScreenshots/agrilo_TestResults.png';
import agriloProdVideo from '../assets/AgriloVideos/FinalEdit_1.mp4';
import { LinearGradient } from 'react-text-gradients';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const AgriloProductPage = () => {
    return (
        <div className="ZenroProductPage">
            <NavBar />
            <main className='ZenroProductPage-Container'>
                <div className='ZenroPP-titleDiv'>
                    <h1 className='ZenroPP-Title'>
                        <LinearGradient gradient={['to left', 'rgb(246, 246, 74), rgb(50, 226, 55)']} className="Products-header">
                            Agrilo
                        </LinearGradient>
                    </h1>
                    <p className='ZenroPP-TitleText'>Optimize your soil with our suite of testing features</p>
                    <img src={ArrowDown} alt="ArrowDown" className="ZenroPP-down-arrow" />
                </div>
                <div className="ZenroPP-Main-Content">
                    <div className="ZenroPP-50-50-first">
                        <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">Perform soil testing in a fast and streamlined manner.</h2>
                            <p>The Agrilo App simplifies agricultural testing by analyzing levels of key elements in soil, water, and food. Users capture an image of a colorimetric strip, and the app instantly interprets the results, delivering fast and reliable insights to support better farming decisions.</p>
                        </div>
                        <span className="ZenroPP-ImgBlock">
                            <img src={agriloLoginPhoto1} alt="ArrowDown" className="ZenroPP-ImgBlock-Img1" />
                            <img src={agriloLoginPhoto2} alt="ArrowDown" className="ZenroPP-ImgBlock-Img2" />
                        </span>
                    </div>
                    <div className="ZenroPP-50-50-first">
                        <span className="ZenroPP-ImgBlock">
                            <img src={agriloSummaryPhoto1} alt="ArrowDown" className="ZenroPP-ImgBlock-Img1" />
                            <img src={agriloSummaryPhoto2} alt="ArrowDown" className="ZenroPP-ImgBlock-Img3" />
                        </span>
                        <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">Generate instant feedback on every test</h2>
                            <p>Agrilo provides instant, AI-driven feedback using a machine learning model trained on agricultural research. After completing a test, the app recommends tailored actions to optimize your crops. For expert advice, users can access the paired web portal to consult with agricultural specialists.</p>
                        </div>
                    </div>
                    <div className="ZenroPP-50-50-first">
                        <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">The Zenro App conatins various accessibility features to explore your health portfolio in full</h2>
                            <p>The Agrilo App includes a detailed history feature, offering a comprehensive list of all your past tests for easy reference. To enhance usability, the app provides quality-of-life tools such as bar charts to visualize element levels over time and the ability to pin values for categories most relevant to you.</p>
                            <p>Additionally, users can create tasks in a built-in calendar, complete with reminders, to stay on top of important actions and optimize crop management."</p>
                        </div>
                        <span className="ZenroPP-ImgBlock">
                            <img src={agriloResultsPhoto1} alt="ArrowDown" className="ZenroPP-ImgBlock-Img1" />
                            <img src={agriloResultsPhoto2} alt="ArrowDown" className="ZenroPP-ImgBlock-Img2" />
                        </span>
                    </div>
                    <div className="ZenroPP-50-50-first">
                        <div className="ZenroPP-TextBlock">
                            <h2 className="ZenroPP-TextBlock-Title">various accessibility features to explore your Agriculture portfolio in full</h2>
                            <ul className="ZenroPP-UL">
                                <li className="ZenroPP-LI">User history with a list of all tests performed by the user</li>
                                <li className="ZenroPP-LI">Various graphs depicting levels of select values over time</li>
                                <li className="ZenroPP-LI">Integrated weather data</li>
                                <li className="ZenroPP-LI">Detailed test views with feedback and export options</li>
                            </ul>
                        </div>
                        <span className="AgriloPP-VideoBlock">
                            <video autoPlay loop muted>
                                <source src={agriloProdVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </span>
                    </div>
                </div>
            </main>
            <BottomNav/>
        </div>
    );
};

export default AgriloProductPage;