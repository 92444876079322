import React, { useState, useEffect } from 'react';
import './PatientsPage.css';
import PatientsBox from './components/PatientsBox';
import PatientBar from './components/PatientBar';
import RecentAverageBox from './components/RecentAverageBox'
import axios from 'axios';
import ProfHistoryBox from './components/ProfHistoryBox';


const PatientsPage = (access) => {
    const [patients, setPatients] = useState([]);
    const [error, setError] = useState('');
    const [loading1, setLoading1] = useState(true);
    // const patients = [
    //     {
    //         PatientID: 1,
    //         name: "Adams, Liam James",
    //         email: "Fake@gmail.com",
    //         phone: "7809993214",
    //         medical: "History of adrenal insufficiency Current Medications: Prednisone, 5 mg daily"
    //     },
    //     {
    //         PatientID: 2,
    //         name: "Ahmed, Nia ",
    //         email: "Fakey@gmail.com",
    //         phone: "7809993214",
    //         medical: "Very healthy"
    //     },
    // ];


    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getDoctorAssignment/?Type=patients', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;
                console.log(access.access)
                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setPatients(data);
                }
                setLoading1(false);
            })
            .catch(error => {
                setError('An error occurred');
                setLoading1(false);
            });
    }, []);



    const [selectedPatient, setSelectedPatient] = useState(patients[0]);
    const [patientOverview, setPatientOverview] = useState(false)

    const onBackClick = () => {
        setPatientOverview(false)
    };

    return (
        <div className="PatientsPage">
            <h1 className='PatientsPage-h1'> PATIENTS</h1>
            <div className='PatientsPage-line1' />
            {loading1 ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
                    {!patientOverview && (
                        <PatientsBox patients={patients}
                            selectedPatient={selectedPatient}
                            onPatientSelect={setPatientOverview}
                        />
                    )}
                    {patientOverview && (
                        <div>
                            <button className='PatientsPage-back-btn' onClick={() => onBackClick()}>back</button>
                            <RecentAverageBox patient={selectedPatient}
                            access={access}/>
                            <ProfHistoryBox access={access}
                            patient={selectedPatient}/>
                        </div>
                    )}
                    <PatientBar patient={selectedPatient}
                        selectOverview={setPatientOverview}
                        access={access}
                    />
                </>
            )}
        </div>
    );
};

export default PatientsPage;