import './LoginPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  var subdomain = window.location.hostname.split('.');
  if (subdomain[0] === 'www') {
    subdomain = subdomain[1]
  } else {
    subdomain = subdomain[0]
  }

  useEffect(() => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('access=')) {
        // Extract the cookie value
        var accessToken = cookie.substring('access='.length);
      }
    }
    if (accessToken) {
      console.log(accessToken)
      if (subdomain === 'agrilo') {
        navigate('/agrilo');
      } else {
        navigate('/zenro');
      }
    }
  }, []);


  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLoginBTNClicked = async (e) => {
    e.preventDefault();
    const dataToSend = { Username: username, password: password };
    if (username != '' && password != '') {
      try {
        const response = await fetch('https://www.pimasens.com/api/token/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataToSend),
        });

        const result = await response.json();

        if (response.ok) {
          document.cookie = "access=" + result.access + ";path=/";
          document.cookie = "Username=" + username + ";path=/";
          if (subdomain === 'agrilo') {
            navigate('/agrilo', { state: { access: result.access } });
          } else {
            navigate('/zenro', { state: { access: result.access } });
          }
        } else {
          alert("Incorrect login Info");

        }
      } catch (err) {
        setError('An error occurred');
      }
    } else {
      alert("Please fill in all fields");
    }
  };

  const styles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Center the image vertically
  };

  const lineStyles = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '43%',
    width: '1px',
    backgroundColor: 'white',
    background: 'rgba(255, 255, 255, 0.40)',
  };

  const inputStyles = {
    borderRadius: '10px',
    border: '1px solid #9CA5B6',
    background: 'rgba(231, 231, 231, 0.19)',
    display: 'inline-flex',
    padding: '10px 16px 10px 30px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '171px',
  };


  return (
    <div className='loginPage'>
      <img src={LoginLogo} alt="LOGO" className="left-image" />
      <div className='loginPage-VerticalLine' style={lineStyles} />
      <form className='LoginPage-form'>
        <img src={LoginBarLogo} alt="LOGOBar" className="right-image-login" />
        <h1 className='welcome-text-login'>Welcome to {subdomain === 'zenro' && "Zenro"}{subdomain === 'agrilo' && "Agrilo"}</h1>
        <input
          type="text"
          placeholder="UserName"
          className='LoginPage-username-input'
          value={username}
          onChange={handleUsernameChange}
        />
        <input
          type="password"
          placeholder="Password"
          className='LoginPage-password-input'
          value={password}
          onChange={handlePasswordChange}
        />
        <input className='signin-button' type="submit" value="Sign In" onClick={handleLoginBTNClicked} />
        <h2 className='signin-agree'>By logging in, you agree to our  <a className='signin-privacy' href="/about/privacy-policy">Privacy Policy</a></h2>
        <a className='signin-forgot' href="/send">Forgot Password?</a>
        <img src={LoginSmallLogo} alt="LOGOSmall" className="bottom-right-image" />
      </form>



    </div>
  );
};

export default LoginPage;
