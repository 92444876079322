import React, { useMemo, useState, useEffect } from 'react';
import './MessagePage.css';
import MessageBox from './components/MessageBox';
import ComposedBox from './components/ComposedBox';
import axios from 'axios';
import DisplayMessageBox from './components/DisplayMessageBox';
const MessagePage = (access) => {
    const [emails, setEmails] = useState([]);
    const [patients, setPatients] = useState([]);
    const [loading1, setLoading1] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [selectedMail, setSelectedMail] = useState(null);
    const [error, setError] = useState('');
    const [showCompose, setCompose] = useState(1)
    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getZenroMailFilter/', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setEmails(data);
                }
                setLoading1(false);
            })
            .catch(error => {
                setError('An error occurred');
                setLoading1(false);
            });
    }, []);

    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getDoctorAssignment/?Type=patient', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;
                console.log(access.access)
                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setPatients(data);
                }
                setLoading2(false);
            })
            .catch(error => {
                setError('An error occurred');
                setLoading2(false);
            });
    }, []);

    // const emails = [
    //     {
    //         EmailID: 1,
    //         type: "inbox",
    //         from: "Dr. Rishi Paul",
    //         Date: "2024-08-08T12:00:00.000Z",
    //         text: "Got an adrenaline test result at 1008 pg/mL. It’s slightly elevated. Recently been dealing with work stress and some anxiety. Looking for advice on managing this and if there’s anything else I should be aware of."
    //     },
    //     {
    //         EmailID: 2,
    //         type: "inbox",
    //         from: "Dr. Rishi Paul",
    //         Date: "2024-08-05T10:30:00.000Z",
    //         text: "Got an adrenaline test result at 1008 pg/mL. It’s slightly elevated. Recently been dealing with work stress and some anxiety. Looking for advice on managing this and if there’s anything else I should be aware of."
    //     },
    // ];

    return (
        <div className="MessagePage">
            <h1 className='MessagePage-h1'> PATIENTS</h1>
            <div className='MessagePage-line1' />

            {loading1 && loading2 ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
                    <MessageBox Emails={emails}
                        access={access.access}
                        selectedMail={setSelectedMail}
                        showCompose={showCompose}
                        setCompose={setCompose} />
                    {!showCompose && (
                        <ComposedBox Patients={patients}
                            access={access.access} setCompose={setCompose} />
                    )}
                    <DisplayMessageBox mail={selectedMail} />
                </>
            )}

        </div>
    );
};

export default MessagePage;