import React, { useState, useEffect } from 'react';
import './MessagesBox.css';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import classNames from "react-day-picker/style.module.css";
import axios from 'axios';
import { jsPDF } from "jspdf";

const MessagesBox = ({ emails, access, showCompose, setCompose}) => {
    const [givenEmails, setEmails] = useState(emails)
    const [error, setError] = useState('');
    const [inbox, setInbox] = useState(true)
    const [mail, setMail] = useState(null)
    const [sentBtn, setSentBtn] = useState(0)
    const [inboxBtn, setInboxBtn] = useState(1)
    const BtnColor = ["#3C3C40", "#1E1E20"]
    const BtnState = ["+","-"]
    // const headers = {
    //     'Authorization': 'Bearer ' + access
    // }

    // const onSearchClick = () => {
    //     if (range.from !== '') {
    //         setFrom(format(range.from, 'yyyy-MM-dd'))
    //     }
    //     if (range.to !== '') {
    //         setTo(format(range.to, 'yyyy-MM-dd'))
    //     }

    //     const queryParams = {
    //         StartDate: from,
    //         EndDate: to,
    //         TestSeverity: selectedSeverity,
    //     };



    //     axios.get('https://www.pimasens.com/api/getTestFilter/', {
    //         headers: headers,
    //         params: queryParams
    //     })
    //         .then(response => {
    //             const data = response.data;

    //             if (data.error == "No tests found") {
    //                 setError('An error occurred');
    //             } else {
    //                 const tests = data;
    //                 setTests(tests)
    //             }
    //         })
    //         .catch(error => {
    //             setError('An error occurred');
    //         });
    // }

    const handleItemClick = (email) => {
        setInbox(!inbox)
        setMail(email)
    };

    const handleBackClick = () => {
        setInbox(!inbox)
        setMail(null)
    };

    const handleSentClick = () => {
        setSentBtn(1)
        setInboxBtn(0)
        axios.get('https://www.pimasens.com/api/getZenroMailFilter/?Type=From', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setEmails(data);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }

    const handleInboxClick = () => {
        setSentBtn(0)
        setInboxBtn(1)
        axios.get('https://www.pimasens.com/api/getZenroMailFilter/?Type=To', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setEmails(data);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }

    const handleComposedClicked = () => {
        if (showCompose==0) {setCompose(1)}
        else {setCompose(0)}
    }

    return (
        <div className="MessagesBox">
            <h1 className='MessagesBox-h1'>MESSAGES</h1>
            <button className='MessagesBox-compose-btn' onClick={() => handleComposedClicked()}>{BtnState[showCompose]}</button>
            {/* options */}
            {inbox && (
                <div className='MessagesBox-top-box'>
                    <div className='MessagesBox-notification-box'>
                        <h1 className='MessagesBox-notification-text'>{emails.length}</h1>
                    </div>
                    <div className='MessagesBox-options'>
                        <button className='MessagesBox-sent-btn'
                            onClick={() => handleSentClick(emails)}
                            style={{ backgroundColor: BtnColor[sentBtn] }}>SENT</button>
                        <button className='MessagesBox-inbox-btn'
                            onClick={() => handleInboxClick(emails)}
                            style={{ backgroundColor: BtnColor[inboxBtn] }}>INBOX</button>
                    </div>
                </div>
            )}
            {inbox && (
                < div className="MessagesBox-item-container">
                    <nav className="MessagesBox-items">

                        <ul className='MessagesBox-ul'>
                            {givenEmails.slice().map(emails => (
                                <li
                                    key={emails.ZenroMailID}
                                    className={`MessagesBox-email-item`}
                                    onClick={() => handleItemClick(emails)}
                                >
                                    <span className="MessagesBox-email-sender">{emails.FromFirstName + " " + emails.FromLastName}</span>
                                    <span className="MessagesBox-item-date">{new Date(emails.Date).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}</span>.
                                    <span className="MessagesBox-email-text">{(emails.ToMessage).slice(0, 40) + "..."}</span>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            )}
            {!inbox && (
                <div className='MessagesBox-open-email-box'>
                    <button className='MessagesBox-back-btn' onClick={() => handleBackClick()}>Back</button>
                    <div className='MessagesBox-info-box'>
                        <div className='MessagesBox-subject-box'>
                            <h1 className='MessagesBox-email-info-text'>Subject: {mail.FromSubject}</h1>
                        </div>
                        <div className='MessagesBox-date-box'>
                            <h1 className='MessagesBox-email-info-text'>{"Date: " + Date(mail.Time).toLocaleString()}</h1>
                        </div>
                        <div className='MessagesBox-from-box'>
                            <h1 className='MessagesBox-email-info-text'>From: {mail.FromFirstName + " " + mail.FromLastName}</h1>
                        </div>
                        <div className='MessagesBox-to-box'>
                            <h1 className='MessagesBox-email-info-text'>To: {mail.ToFirstName + " " + mail.ToLastName}</h1>
                        </div>
                    </div>
                    <h2 className='MessagesBox-email-content'>
                        {mail.FromMessage}
                    </h2>
                </div>
            )}
        </div >
    );
};


export default MessagesBox;