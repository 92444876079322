import React from 'react';
import './AboutUsPage.css';
import { Helmet } from 'react-helmet';

import NavBar from '../components/NavBar';
import BottomNav from '../components/BottomNav'
import ContactBox from '../components/ContactBox';

const ComingSoonPage = () => {
    return (
        <div className="AboutUs">
            <Helmet>
                <meta name="description" content="Explore what makes our mission at Pimasens special." />
                <meta name="keywords" content="Mental Health, Agriculture, Therapy, Performance, Mobile App" />
                <meta name="author" content="Pimasens" />
                <meta property="og:title" content="About Us - Pimasens" />
                <meta property="og:description" content="Explore what makes our mission at Pimasens special." />
                <meta property="og:url" content="https://www.pimasens.com/about-us" />
                <meta property="og:image" content="https://www.pimasens.com/BlackBackLogo.png" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <NavBar/>
            <div className='AboutUs-Container'>
                <h1 className='AboutUs-Title'>About Us</h1>
                <span className="AboutUs-Span">
                    <p className="AboutUs-text">At PimaSense, we are driven by a passion for innovation in health and agriculture. Our mission is to empower individuals, medical professionals, and farmers with advanced sensor technology that delivers real-time, actionable data. By harnessing cutting-edge science, we bridge the gap between precision and simplicity, helping users optimize their well-being and crop yields with confidence.</p>
                    <p className="AboutUs-text">We envision a world where the power of data enables smarter decisions, healthier lifestyles, and more sustainable farming. Whether it's tracking cortisol levels to improve stress management or analyzing soil health for maximum agricultural productivity, our solutions are designed to make a meaningful impact.</p>
                </span>
            </div>
            <BottomNav/>
        </div>
    );
};

export default ComingSoonPage;