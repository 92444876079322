import React, { useState, useEffect } from 'react';
import './HistoryPage.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import HistoryBox from '../components/HistoryBox';
import SelectHistoryBox from '../components/SelectHistoryBox';



const HistoryPage = (access) => {
    const [selectedTest, setSelectedTest] = useState(null);

    const [error, setError] = useState('');
    const [tests, setTests] = useState([]);
    const [loading, setLoading] = useState(true);
    const headers = {
        'Authorization': 'Bearer ' + access.access
    }
    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getTestFilter/', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    setTests(data);
                }
                setLoading(false);
            })
            .catch(error => {
                setError('An error occurred');
                setLoading(false);
            });

            // axios.get('https://www.pimasens.com/api/getTestFilter/', {
            //     headers: headers,
            // })
            //     .then(response => {
            //         const data = response.data;
    
            //         if (data.error == "No tests found") {
            //             setError('An error occurred');
            //         } else {
            //             setTests(tests)
            //         }
            //         setLoading(false);
            //     })
            //     .catch(error => {
            //         setError('An error occurred');
            //         setLoading(false);
            //     });
    }, []);

    useEffect(() => {
        if (tests.length > 0) {
          setSelectedTest(tests[tests.length - 1]);
        }
      }, [tests]);

    return (
        <div className="HistoryPage">
            {loading ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
            <HistoryBox testData={tests}
            selectedTest={selectedTest}
            access={access.access}
            onTestSelect={setSelectedTest}
            title={'RESULTS'}/>
            <SelectHistoryBox testData={selectedTest} access={access.access}/>
                </>
            )}
        </div>
    );


};

export default HistoryPage;