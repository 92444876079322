import React, { useState, useEffect } from 'react';
import './AgriloTaskBar.css';

const AgriloTaskBar = ({ tests, prefill }) => {
    const [givenTest, setTests] = useState(tests)

    const DateConverter = (startDate, endDate) => {
        const start = new Date(startDate + 'T12:00:00Z');
        const end = new Date(endDate + 'T12:00:00Z');

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const startMonth = monthNames[start.getMonth()];
        const startDay = start.getDate();
        const endMonth = monthNames[end.getMonth()];
        const endDay = end.getDate();

        if (start.toDateString() === end.toDateString()) {
            return `${startMonth} ${startDay}`;
        } else if (startMonth === endMonth) {
            return `${startMonth} ${startDay}-${endDay}`;
        } else {
            return `${startMonth} ${startDay}-${endMonth} ${endDay}`;
        }
    }

    console.log(tests)
    console.log(givenTest)

    return (
        <div className="AgriloTaskBar">
            <div className='AgriloTaskBar-event-start'>
                <h1 className='AgriloTaskBar-event-text'>Tests</h1>
            </div>
            <div className="AgriloTaskBar-item-container">
                <nav className="AgriloTaskBar-items">
                    <ul className='AgriloTaskBar-ul'>
                        {tests.slice().map(test => (
                            <li
                                key={test.AgriloTestID}
                                className='AgriloTaskBar-test-item'
                            >
                                <div className='AgriloTaskBar-eventbox'>
                                    <div className='AgriloTaskBar-task-color' style={{ backgroundColor: 'black' }} />
                                    <h1 className='AgriloTaskBar-task-name'>{prefill["specs"]["0"]["SpecName"]} | {new Date(test.TimeCreated).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}</h1>
                                    <h1 className='AgriloTaskBar-task-range'>{prefill["crop_types"][test.CropTypeID-1]["CropName"]} | {prefill["chemicals"][test.ChemicalID-1]["ChemicalName"]}</h1>
                                </div>
                                <div className='AgriloTaskBar-description'>
                                    <h1 className='AgriloTaskBar-description-header'>Recommendation</h1>
                                    <h1 className='AgriloTaskBar-description-text'>{test.Desc}</h1>
                                </div>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default AgriloTaskBar;