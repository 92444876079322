import React from 'react';
import './AgriloOverviewDisplay.css';

const AgriloOverviewDisplay = ({ averages }) => {
    return (
        <div className="AgriloOverviewDisplay">
            {/* the right bar */}
            <h1 className='AgriloOverviewDisplay-title'>{averages[0].range}</h1>
            <div className='AgriloOverviewDisplay-bar'>
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-high'>High</div>
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-prime' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-optimal'>Optimal</div>
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-prime' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick-low'>Low</div>
                <div className='AgriloOverviewDisplay-tick' />
                <div className='AgriloOverviewDisplay-tick' />
            </div>
            <h1 className='AgriloOverviewDisplay-date'>{averages[0].months}</h1>
            <div className='AgriloOverviewDisplay-chemcials-box'>
                {averages[1] && (
                    <ChemicalBar
                        name={averages[1].name}
                        number={averages[1].number}
                        rangeLow={averages[1].rangeLow}
                        rangeHigh={averages[1].rangeHigh}
                        rangeMax={averages[1].rangeMax}
                    />
                )}

                {averages[2] && (
                    <ChemicalBar
                        name={averages[2].name}
                        number={averages[2].number}
                        rangeLow={averages[2].rangeLow}
                        rangeHigh={averages[2].rangeHigh}
                        rangeMax={averages[2].rangeMax}
                    />
                )}
                {averages[3] && (
                    <ChemicalBar
                        name={averages[3].name}
                        number={averages[3].number}
                        rangeLow={averages[3].rangeLow}
                        rangeHigh={averages[3].rangeHigh}
                        rangeMax={averages[3].rangeMax}
                    />
                )}
                {averages[4] && (
                    <ChemicalBar
                        name={averages[4].name}
                        number={averages[4].number}
                        rangeLow={averages[4].rangeLow}
                        rangeHigh={averages[4].rangeHigh}
                        rangeMax={averages[4].rangeMax}
                    />
                )}
            </div>
        </div>
    );
};

export default AgriloOverviewDisplay;


function ChemicalBar(props) {
    var testColor;
    const { name, rangeLow, rangeHigh, rangeMax, number } = props
    var heightPX = 0;
    var percentage = 0;
    var percentage = 0;
    if (number < rangeLow) {
        percentage = number / rangeLow
        heightPX = percentage * 4.5
        testColor = '#CFBF05'
    } else if (number > rangeHigh) {
        percentage = (number - rangeHigh) / (rangeMax - rangeHigh)
        testColor = '#C83A31'
        if (percentage > 1) {
            heightPX = 15;
        } else { heightPX = percentage * 4.3 + 10.65 }
    } else {
        testColor = '#4C6CFD'
        percentage = (number - rangeLow) / (rangeHigh - rangeLow)
        heightPX = percentage * 4.4 + 5.3
    }
    return (
        <div className='AgriloTestsBox-chemcial'>
            <div className='AgriloTestsBox-chemical-bar' style={{ height: heightPX.toString() + "rem" }} />
            <div className='AgriloTestsBox-chemical-name-box'>{name}</div>
            <div className='AgriloTestsBox-chemical-dot' style={{ backgroundColor: testColor, bottom: (heightPX + 2).toString() + "rem" }} />
            <h1 className='AgriloTestsBox-chemical-result' style={{ color: testColor, bottom: (heightPX + 2).toString() + "rem" }}>{number}</h1>
            <h1 className='AgriloTestsBox-chemical-metric' style={{ bottom: (heightPX + 2.25).toString() + "rem" }}>ppm</h1>
        </div>
    );
}