import React, { useMemo, useState, useEffect } from 'react';
import './MessagingPage.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import ComposeBox from './components/ComposeBox';
import MessagesBox from './components/MessagesBox';



const MessagingPage = (access) => {
    const [selectedTest, setSelectedTest] = useState(null);

    const [error, setError] = useState('');
    const [emails, setEmails] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [loading2, setLoading1] = useState(true);
    const [loading1, setLoading2] = useState(true);
    const [showCompose, setCompose] = useState(0)

    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getZenroMailFilter/?Type=To', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setEmails(data);
                }
                setLoading1(false);
            })
            .catch(error => {
                setError('An error occurred');
                setLoading1(false);
            });
    }, []);

    useEffect(() => {
        axios.get('https://www.pimasens.com/api/getDoctorAssignment/?Type=doctor', {
            headers: {
                'Authorization': 'Bearer ' + access.access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setDoctors(data);
                }
                setLoading2(false);
            })
            .catch(error => {
                setError('An error occurred');
                setLoading2(false);
            });
    }, []);

    const email = [
        {
            EmailID: 1,
            type: "inbox",
            from: "Dr. Rishi Paul",
            Date: "2024-08-08T12:00:00.000Z",
            ToMessage: "Got an adrenaline test result at 1008 pg/mL. It’s slightly elevated. Recently been dealing with work stress and some anxiety. Looking for advice on managing this and if there’s anything else I should be aware of."
        },
        {
            EmailID: 2,
            type: "inbox",
            from: "Dr. Rishi Paul",
            Date: "2024-08-05T10:30:00.000Z",
            ToMessage: "Got an adrenaline test result at 1008 pg/mL. It’s slightly elevated. Recently been dealing with work stress and some anxiety. Looking for advice on managing this and if there’s anything else I should be aware of."
        },
    ];


    return (
        <div className="MessagingPage">
            {loading1 && loading2 ? (
                <div>Loading...</div> // Show a loading indicator while data is being fetched
            ) : (
                <>
                    {showCompose && (
                        <ComposeBox Doctors={doctors} access={access.access} setCompose={setCompose} />

                    )}
                    <MessagesBox emails={emails} access={access.access} showCompose={showCompose} setCompose={setCompose} />
                    {/* <HistoryBox testData={tests}
            selectedTest={selectedTest}
            access={access.access}
            onTestSelect={setSelectedTest}
            title={'RESULTS'}/>
            <SelectHistoryBox testData={selectedTest} access={access.access}/> */}
                </>
            )}
        </div>
    );


};

export default MessagingPage;