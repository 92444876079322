import React, { useState, useEffect } from 'react';
import './MessageBox.css';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import classNames from "react-day-picker/style.module.css";
import axios from 'axios';
import { jsPDF } from "jspdf";

const MessageBox = ({ Emails, access, selectedMail, showCompose, setCompose }) => {
    const [givenEmails, setEmails] = useState(Emails)
    const [error, setError] = useState('');
    const [inbox, setInbox] = useState(true)
    const [sentBtn, setSentBtn] = useState(0)
    const [inboxBtn, setInboxBtn] = useState(1)
    const BtnColor = ["#7b7b83", "#1E1E20"]
    const BtnState = ["-","+"]
    const handleSentClick = () => {
        setSentBtn(1)
        setInboxBtn(0)
        axios.get('https://www.pimasens.com/api/getZenroMailFilter/?Type=From', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;

                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setEmails(data);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }

    const handleInboxClick = () => {
        setSentBtn(0)
        setInboxBtn(1)
        axios.get('https://www.pimasens.com/api/getZenroMailFilter/?Type=To', {
            headers: {
                'Authorization': 'Bearer ' + access
            }
        })
            .then(response => {
                const data = response.data;
                if (data.error === "No tests found") {
                    setError('An error occurred');
                } else {
                    console.log(data)
                    setEmails(data);
                }
            })
            .catch(error => {
                setError('An error occurred');
            });
    }


    const handleItemClick = (email) => {
        selectedMail(email)
    };

    const handleComposedClicked = () => {
        if (showCompose==0) {setCompose(1)}
        else {setCompose(0)}
    }
    return (
        <div className="MessageBox">
            <h1 className='MessageBox-h1'>MESSAGES</h1>
            <button className='MessagesBox-compose-btn' onClick={() => handleComposedClicked()}>{BtnState[showCompose]}</button>
            <div className='MessageBox-options'>
                <button className='MessageBox-sent-btn'
                    onClick={() => handleSentClick()}
                    style={{ backgroundColor: BtnColor[sentBtn] }}>SENT</button>
                <button className='MessageBox-inbox-btn'
                    onClick={() => handleInboxClick()}
                    style={{ backgroundColor: BtnColor[inboxBtn] }}>INBOX</button>
            </div>
            <div className='MessageBox-line1' />
            {/* Container of emails */}
            <div className="MessageBox-item-container">
                <nav className="MessageBox-items">

                    <ul className='MessageBox-ul'>
                        {givenEmails.slice().map(emails => (
                            <li
                                key={Emails.ZenroMailID}
                                className={`MessageBox-email-item`}
                                onClick={() => handleItemClick(emails)}
                            >
                                <span className="MessageBox-email-sender">{emails.FromFirstName + " " + emails.FromLastName}</span>
                                <span className="MessageBox-item-date">{new Date(emails.Date).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}</span>.
                                <span className="MessageBox-email-text">{(emails.ToMessage).slice(0, 40) + "..."}</span>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};


export default MessageBox;