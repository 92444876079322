import React, { useState, useEffect } from 'react';
import './AgriloRecentTestBox.css';

const AgriloRecentTestBox = ({ test, prefill}) => {
    const testColor = ['#CFBF05', '#4C6CFD', '#C83A31']
    const recommendation = ['Low', 'Optimal', 'High']
      const [rating, setRating] = useState(1);
      useEffect(() => {
        if (test != null) {
          if (test.PpmRecorded > 40) {
            setRating(2)
          } else if (test.PpmRecorded < 20) {
            setRating(0)
          }
        }
      }, [test]);
    return (
        <div className="AgriloRecentTestBox">
            <div className='AgriloRecentTestBox-top-box'>
                <h1 className='AgriloRecentTestBox-h1'>{prefill["specs"]["0"]["SpecName"]} | {new Date(test.TimeCreated).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })} </h1>
                <div className='AgriloRecentTestBox-status' style={{ backgroundColor: testColor[rating] }}>
                    <h4 className='AgriloRecentTestBox-h4'>{recommendation[rating]}</h4>
                </div>
            </div>
            <div className='AgriloRecentTestBox-type-boxies'>
                <div className='AgriloRecentTestBox-type1'>
                    <h5 className='AgriloRecentTestBox-h5'>{prefill["crop_types"][test.CropTypeID-1]["CropName"]}</h5>
                </div>
                <div className='AgriloRecentTestBox-type2'>
                    <h5 className='AgriloRecentTestBox-h5'>{prefill["chemicals"][test.ChemicalID-1]["ChemicalName"]}</h5>
                </div>
            </div>
            <div className='AgriloRecentTestBox-results'>
                <h2 className='AgriloRecentTestBox-h2' style={{ color: testColor[rating] }}>{test.PpmRecorded}</h2>
                <h3 className='AgriloRecentTestBox-h3'>ppm</h3>
            </div>
        </div>
    );
};

export default AgriloRecentTestBox;
