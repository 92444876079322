import './SignUpPage.css';
import LoginLogo from '../assets/LoginLogo.png'
import LoginBarLogo from '../assets/LoginBarLogo.png'
import LoginSmallLogo from '../assets/LoginSmallLogo.png'
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { GrDomain } from 'react-icons/gr';


const SignUpPage = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const buttonLabel = ["Next", "Next", "Next", "Submit"]
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Username: '',
    Email: '',
    Phone: '',
    HealthID: '',
    password: '',
  });

  const [agriloFormData, setAgriloFormData] = useState({
    FirstName: '',
    LastName: '',
    Username: '',
    Email: '',
    Phone: '',
    postal_code: '',
    password: '',
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(true);
  var subdomain = window.location.hostname.split('.');
  if (subdomain[0] === 'www') {
    subdomain = subdomain[1]
  } else {
    subdomain = subdomain[0]
  }


  const handleChange = (e) => {
    const { id, value } = e.target;
    if (subdomain === 'agrilo') {
      setAgriloFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formData).some((value) => value === '' && subdomain == "zenro")) {
      alert('Please fill in all the fields');
      setCurrentStep(4)
      return false;
    }
    if (Object.values(agriloFormData).some((value) => value === '' && subdomain == "agrilo")) {
      alert('Please fill in all the fields');
      setCurrentStep(4)
      return false;
    }
    if (subdomain == "agrilo") {
      var dataToSend = { ...agriloFormData, phone: agriloFormData.Phone };
      var url = 'https://www.pimasens.com/api/createAgriloUser/'
    } else {
      var url = 'https://www.pimasens.com/api/CreateUser/'
      const dataToSend = { ...formData, UserType: 'user', phone: formData.Phone };
    }


    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();

      if (response.ok) {
        setShowForm(false);
      } else {
        if (result.Email) {
          alert('There is already an account with this email');
        } else if (result.HealthID) {
          alert('There is already an account with this HealthID');
        } else if (result.Username) {
          alert('There is already an account with this Username');
        } else {
          alert('An error occurred');
        }
        setCurrentStep(4)
      }
    } catch (err) {
      setError('An error occurred');
      console.error('Network error:', err);
    }
  };

  const nextStep = () => {
    setCurrentStep((currentStep + 1));
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <input className='SignUpPage-input' placeholder="First Name" type="text" id="FirstName" name="FirstName" value={subdomain === 'agrilo' ? agriloFormData.FirstName : formData.FirstName}  onChange={handleChange} />
            <input className='SignUpPage-input' placeholder="Last Name" type="text" id="LastName" name="LastName" value={subdomain === 'agrilo' ? agriloFormData.LastName : formData.LastName}  onChange={handleChange} />
          </div>
        );
      case 2:
        return (
          <div>
            <input className='SignUpPage-input' placeholder="Email" type="email" id="Email" name="Email" value={subdomain === 'agrilo' ? agriloFormData.Email : formData.Email} onChange={handleChange} />
            <input className='SignUpPage-input' placeholder="Phone" type="tel" id="Phone" name="Phone" value={subdomain === 'agrilo' ? agriloFormData.Phone : formData.Phone} onChange={handleChange} />
          </div>
        );
      case 3:
        return (
          <div>
            {subdomain === 'agrilo' && <input className='SignUpPage-input' placeholder="postal code" type="text" id="postal_code" name="postal_code" value={agriloFormData.postal_code} onChange={handleChange} />}
            {subdomain === 'zenro' && <input className='SignUpPage-input' placeholder="Health ID" type="text" id="HealthID" name="HealthID" value={formData.HealthID} onChange={handleChange} />}
            {/* <input className='SignUpPage-input' placeholder="Health ID" type="text" id="HealthID" name="fname" value={formData.HealthID} onChange={handleChange} /> */}
          </div>
        );
      case 4:
        return (
          <div>
            <input className='SignUpPage-input' placeholder="Username" type="text" id="Username" name="Username" value={subdomain === 'agrilo' ? agriloFormData.Username : formData.Username} onChange={handleChange} />
            <input className='SignUpPage-input' placeholder="Password" type="password" id="password" name="password" value={subdomain === 'agrilo' ? agriloFormData.password : formData.password} onChange={handleChange} />
          </div>
        );
      default:
        return (
          <h1 className="SignUpPage-loading-text">Submitting...</h1>
        );
    }
  };

  const lineStyles = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '43%',
    width: '1px',
    backgroundColor: 'white',
    background: 'rgba(255, 255, 255, 0.40)',
  };

  return (
    <div className="signUp">
      {showForm && (
        <div className="signUp-box">
          <img src={LoginLogo} alt="LOGO" className="left-image" />
          <div style={lineStyles} />
          <form className="SignUpPage-form" onSubmit={handleSubmit}>
            <img
              src={LoginBarLogo}
              alt="LOGOBar"
              className="SignUpPage-right-image"
            />
            <h1 className="SignUpPage-welcome-text-signup">Welcome to {subdomain === 'zenro' && "Zenro"}{subdomain === 'agrilo' && "Agrilo"}</h1>

            {renderStep()}

            <div style={{ overflow: 'auto' }}>
              <div className='SignUpPage-button-box' style={{ float: 'right' }}>
                {currentStep > 1 && (
                  <button className='signup-button' type="button" onClick={prevStep}>
                    Previous
                  </button>
                )}
                {currentStep < 5 ? (
                  <button className='signup-button' type="button" onClick={nextStep}>
                    {buttonLabel[currentStep - 1]}
                  </button>
                ) : (
                  <button className='signup-button' type="submit" onClick={nextStep}>Submit</button>
                )}

              </div>
            </div>

            <div style={{ textAlign: 'center', marginTop: '40px' }}>
              {[...Array(4)].map((_, i) => (
                <span
                  key={i}
                  className={`step ${currentStep === i + 1 ? 'active' : ''}`}
                ></span>
              ))}
            </div>

            <img
              src={LoginSmallLogo}
              alt="LOGOSmall"
              className="SignUpPage-bottom-right-image"
            />
          </form>
        </div>
      )}
      {!showForm && (
        <div className="signUp-box-email">
          <h2 className="signUp-h2">
            You will receive an e-mail shortly with instructions detailing how
            to finish your registration. Please check your junk mail and allow
            e-mails from mailgun@pimasens.com
          </h2>
        </div>
      )}
    </div>
  );
};

export default SignUpPage;