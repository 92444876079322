import React, { useCallback, useMemo, useState, useEffect } from 'react';
import isHotkey from 'is-hotkey'
import axios from 'axios';
import './ComposeBox.css';
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
    Editor,
    Transforms,
    createEditor,
    Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'
import { Button, Toolbar } from './/index'
import { FaBold, FaItalic, FaUnderline, FaQuoteLeft, FaListOl, FaListUl, FaAlignLeft, FaAlignCenter, FaAlignRight } from 'react-icons/fa'; // Import icons from Font Awesome
import { MdLooksOne, MdLooksTwo } from 'react-icons/md'; // Import icons from Material Design
const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right']
const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)
    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes(editor, newProperties)
    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}
const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)
    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}
const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false
    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )
    return !!match
}
const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}
const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}
const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }
    if (leaf.code) {
        children = <code>{children}</code>
    }
    if (leaf.italic) {
        children = <em>{children}</em>
    }
    if (leaf.underline) {
        children = <u>{children}</u>
    }
    return <span {...attributes}>{children}</span>
}
const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    return (
        <Button
            active={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'

            )}
            onMouseDown={event => {
                event.preventDefault();
                toggleBlock(editor, format);
            }}
        >
            {icon
                === 'looks_one' ? (
                <MdLooksOne />
            ) : icon === 'looks_two' ? (
                <MdLooksTwo />
            ) : icon === 'format_quote' ? (
                <FaQuoteLeft />
            ) : icon === 'format_list_numbered' ? (
                <FaListOl />
            ) : icon === 'format_list_bulleted' ? (
                <FaListUl />
            ) : icon === 'format_align_left' ? (
                <FaAlignLeft />
            ) : icon === 'format_align_center' ? (
                <FaAlignCenter />
            ) : icon === 'format_align_right' ? (
                <FaAlignRight />
            ) : null}
        </Button>
    );
}
const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            {icon
                === 'format_bold' ? (
                <FaBold />
            ) : icon === 'format_italic' ? (
                <FaItalic />
            ) : icon === 'format_underlined' ? (
                <FaUnderline />
            ) : null}
        </Button>
    )
}
const initialValue = [
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
]



const ComposeBox = ({ Doctors, access, setCompose}) => {
    const [showPicker, setShowPicker] = useState(false);
    const [added, setAdded] = useState(true);
    const [picked, setPicked] = useState(false);
    const [name, setName] = useState("")
    const [assignmentID, setAssignmentID] = useState(null)
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    const [value1, setValue1] = useState('No Subject');
    const handleAddClick = () => {
        setShowPicker(!showPicker)
    };

    const handleNameClick = () => {
        setAdded(!added)
        setPicked(!picked)
        setName("")
        setAssignmentID()
    };

    const handleItemClick = (doctors) => {
        setShowPicker(!showPicker)
        setAdded(!added)
        setPicked(!picked)
        setAssignmentID(doctors.DoctorAssignmentID)
        setName(doctors.DocFirstName + " " + doctors.DocLastName)
    };

    const handleSendClick = async (e) => {
        const message = Editor.string(editor, []);
        if (name == '') {
            alert("Please pick recipient");
        } else {
            let dataToSend = { DoctorAssignmentID: assignmentID, Message: message, Subject: value1 };
            axios.post('https://www.pimasens.com/api/sendZenroMail/', dataToSend, {
                headers: {
                    'Authorization': 'Bearer ' + access,
                }
            })
                .then(function (response) {
                    // Handle the successful response
                    alert("Mail send successfully");
                    setAdded(!added)
                    setPicked(!picked)
                    setName("")
                    setAssignmentID()
                    Transforms.delete(editor, {
                        at: {
                            anchor: Editor.start(editor, []),
                            focus: Editor.end(editor, []),
                        },
                    });
                })
                .catch(function (error) {
                    // Handle errors
                    console.error('Error sending mail:', error);
                    alert("Failed to sent try again later");
                });
        }
    };

    const handleComposedClicked = () => {
        setCompose(0)
    }


    return (
        <div className="ComposeBox">
            <h1 className='ComposeBox-header-text'>COMPOSE</h1>
            <button className='ComposeBox-compose-btn'onClick={() => handleComposedClicked()}>X</button>
            <div className='ComposeBox-to-box'>
                <h1 className='ComposeBox-to-text'>To:</h1>
                <div className='ComposeBox-line1' />
                {added && (
                    <button className='ComposeBox-add-btn' onClick={() => handleAddClick()}>Add</button>
                )}
                {picked && (
                    <button className='ComposeBox-picked-btn' onClick={() => handleNameClick()}>{name}</button>
                )}
                {showPicker && (
                    <div className='ComposeBox-add-box'>
                        <div className="ComposeBox-item-container">
                            <nav className="ComposeBox-items">
                                <ul className='ComposeBox-ul'>
                                    {Doctors.slice().map(doctors => (
                                        <li
                                            key={doctors.DoctorAssignmentID}
                                            className={`ComposeBox-doctor-item`}
                                            onClick={() => handleItemClick(doctors)}
                                        >
                                            <span className="ComposeBox-doctor-sender">{doctors.DocFirstName + " " + doctors.DocLastName}</span>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                    </div>
                )}
            </div>
            <div className='ComposeBox-subject-box'>
                <h1 className='ComposeBox-subject-text'>Subject:</h1>
                <div className='ComposeBox-line1' />
                <input type="text" className="ComposeBox-subject-input" defaultValue={""} onChange={(e) => setValue1(e.target.value)} />
            </div>
            <div className='ComposeBox-editBox'>
                <Slate editor={editor} initialValue={initialValue}>
                    <Toolbar>
                        <MarkButton format="bold" icon="format_bold" />
                        <MarkButton format="italic" icon="format_italic" />
                        <MarkButton format="underline" icon="format_underlined" />
                        <BlockButton format="heading-one" icon="looks_one" />
                        <BlockButton format="heading-two" icon="looks_two" />
                        <BlockButton format="block-quote" icon="format_quote" />
                        <BlockButton format="numbered-list" icon="format_list_numbered" />
                        <BlockButton format="bulleted-list" icon="format_list_bulleted" />
                        <BlockButton format="left" icon="format_align_left" />
                        <BlockButton format="center" icon="format_align_center" />
                        <BlockButton format="right" icon="format_align_right" />
                    </Toolbar>
                    <Editable

                        style={{
                            background: "#3C3C40",
                            width: "90%",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            height: "400px",
                            overflowY: "auto",
                            paddingLeft: "20px",
                            paddingRight: "20px"
                        }}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        spellCheck
                        autoFocus
                        onKeyDown={event => {
                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event)) {
                                    event.preventDefault()
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }
                            }
                        }}
                    />
                </Slate>
            </div>
            <button className='ComposeBox-send-button' onClick={() => handleSendClick()}>send</button>
        </div>
    );


};

export default ComposeBox;